<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click.self="closeModal">
      <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription" @click.stop>
        <div class="modal-header" id="modalTitle">
          <h3>Редактирование мероприятия</h3>
          <button @click="closeModal" class="close-button">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body" id="modalDescription">
          <div class="body">
            <div class="home">
              <form @submit.prevent="saveEvent()" class="event-form">
                <!-- Основные поля -->
                <label for="eventName">Название</label>
                <input type="text" id="eventName" name="name" class="form-input" placeholder="Название" required
                  v-model="event.name">
                <span v-if="errors.name" class="error">{{ errors.name }}</span>

                <!-- Переключатель для выбора режима -->
                <label>
                  <input type="checkbox" v-model="isDateUnknown" @change="toggleDateInputs" />
                  Точная дата мероприятия неизвестна
                </label>

                <!-- Поля для точной даты и времени -->
                <div v-if="!isDateUnknown">
                  <label for="eventDateStart">Дата начала</label>
                  <input type="date" id="eventDateStart" name="datestart" class="form-input" placeholder="Дата начала"
                    v-model="event.datestart">
                  <span v-if="errors.datestart" class="error">{{ errors.datestart }}</span>

                  <label for="eventDateEnd">Дата окончания</label>
                  <input type="date" id="eventDateEnd" name="dateend" class="form-input" placeholder="Дата окончания"
                    v-model="event.dateend">
                  <span v-if="errors.dateend" class="error">{{ errors.dateend }}</span>

                  <label>
                    <input type="checkbox" v-model="isTimeUnknown" @change="toggleTimeInputs" />
                    Точное время мероприятия неизвестно
                  </label>

                  <div v-if="!isTimeUnknown">
                    <label for="eventTimeStart">Время начала</label>
                    <input type="time" id="eventTimeStart" name="timestart" class="form-input" placeholder="Время начала"
                      v-model="event.timestart">
                    <span v-if="errors.timestart" class="error">{{ errors.timestart }}</span>

                    <label for="eventTimeEnd">Время окончания</label>
                    <input type="time" id="eventTimeEnd" name="timeend" class="form-input" placeholder="Время окончания"
                      v-model="event.timeend">
                    <span v-if="errors.timeend" class="error">{{ errors.timeend }}</span>
                  </div>
                </div>

                <!-- Поля для месяца и года -->
                <div v-else>
                  <label for="eventMonthStart">Месяц начала</label>
                  <select id="eventMonthStart" name="monthstart" class="form-input" v-model="event.monthstart">
                    <option value="">Выберите месяц</option>
                    <option v-for="month in months" :key="month.value" :value="month.value">{{ month.label }}</option>
                  </select>
                  <span v-if="errors.monthstart" class="error">{{ errors.monthstart }}</span>

                  <label for="eventYearStart">Год</label>
                  <input type="number" id="eventYearStart" name="yearstart" class="form-input" placeholder="Год начала"
                    v-model="event.yearstart">
                  <span v-if="errors.yearstart" class="error">{{ errors.yearstart }}</span>
                </div>

                <!-- Остальные поля формы -->
                <label for="eventDescription">Описание</label>
                <input type="text" id="eventDescription" name="description" class="form-input" placeholder="Описание"
                  required v-model="event.description">
                <span v-if="errors.description" class="error">{{ errors.description }}</span>

                <label for="eventImageName">Название изображения</label>
                <input type="text" id="eventImageName" name="imageName" class="form-input"
                  placeholder="Название изображения" v-model="event.imagename">

                <!-- Организаторы -->
                <label for="eventOrganizator">Организатор</label>
                <select id="eventOrganizator" name="organizator" class="form-input" required v-model="event.organizator">
                  <option v-for="org in organizators" :key="org.uid" :value="org.uid">{{ org.fullname }}</option>
                </select>
                <span v-if="errors.organizator" class="error">{{ errors.organizator }}</span>

                <label for="eventSuborganizator">Соорганизатор (-ы)</label>
                <div v-for="(suborg, index) in event.suborganizator" :key="index" class="suborg-input">
                  <select class="form-input" v-model="event.suborganizator[index]">
                    <option v-for="org in organizators" :key="org.uid" :value="org.uid">{{ org.fullname }}</option>
                  </select>
                  <button type="button" @click="removeSuborganizator(index)" class="btn-remove">Удалить</button>
                </div>
                <button type="button" @click="addSuborganizator" class="btn-add">Добавить соорганизатора</button>

                <!-- Место проведения -->
                <label for="eventLocation">Место проведения</label>
                <input type="text" id="eventLocation" name="location" class="form-input" placeholder="Место проведения"
                  required v-model="event.location">
                <span v-if="errors.location" class="error">{{ errors.location }}</span>

                <!-- Класс, тип, уровень, подтип -->
                <label for="eventClass">Класс мероприятия</label>
                <select id="eventClass" name="eventClass" class="form-input" required v-model="event.eventclass">
                  <option v-for="cls in EventClasses" :key="cls.uid" :value="cls.uid">{{ cls.name }}</option>
                </select>
                <span v-if="errors.eventclass" class="error">{{ errors.eventclass }}</span>

                <label for="eventType">Тип мероприятия</label>
                <select id="eventType" name="eventType" class="form-input" required v-model="event.eventtype">
                  <option v-for="type in EventTypes" :key="type.uid" :value="type.uid">{{ type.name }}</option>
                </select>
                <span v-if="errors.eventtype" class="error">{{ errors.eventtype }}</span>

                <label for="eventLevel">Уровень</label>
                <select id="eventLevel" name="level" class="form-input" required v-model="event.level">
                  <option v-for="lvl in Levels" :key="lvl.uid" :value="lvl.uid">{{ lvl.name }}</option>
                </select>
                <span v-if="errors.level" class="error">{{ errors.level }}</span>

                <label for="eventSubType">Подтип мероприятия</label>
                <select id="eventSubType" name="eventSubType" class="form-input" required v-model="event.eventsubtype">
                  <option v-for="subType in EventSubTypes" :key="subType.uid" :value="subType.uid">{{ subType.name }}
                  </option>
                </select>
                <span v-if="errors.eventsubtype" class="error">{{ errors.eventsubtype }}</span>

                <!-- Участники -->
                <label for="eventMember">Участники</label>
                <div v-for="(member, index) in event.members" :key="index" class="member-input">
                  <select class="form-input" v-model="event.members[index]">
                    <option v-for="mem in Members" :key="mem.uid" :value="mem.uid">{{ mem.name }}</option>
                  </select>
                  <button type="button" @click="removeMember(index)" class="btn-remove">Удалить</button>
                </div>
                <button type="button" @click="addMember" class="btn-add">Добавить участника</button>

                <!-- Форма организации -->
                <label for="eventOrganizationForm">Форма организации</label>
                <select id="eventOrganizationForm" name="organizationForm" class="form-input" required
                  v-model="event.organizationform">
                  <option v-for="form in OrganizationForms" :key="form.uid" :value="form.uid">{{ form.name }}</option>
                </select>
                <span v-if="errors.organizationform" class="error">{{ errors.organizationform }}</span>

                <!-- Ответственный -->
                <label for="eventResponsible">ФИО ответственного (-ых)</label>
                <input type="text" id="eventResponsible" name="responsible" class="form-input"
                  placeholder="Ответственный" required v-model="event.responsible">
                <span v-if="errors.responsible" class="error">{{ errors.responsible }}</span>

                <!-- Email организаторов -->
                <label for="eventOrganizerEmails">Email организаторов</label>
                <div v-for="(email, index) in event.responsiblelogin" :key="index" class="email-input">
                  <input type="email" class="form-input" placeholder="Email организатора"
                    v-model="event.responsiblelogin[index]" required>
                  <button type="button" @click="removeOrganizerEmail(index)" class="btn-remove">Удалить</button>
                </div>
                <button type="button" @click="addOrganizerEmail" class="btn-add">Добавить email организатора</button>
                <p class="note">Все email, которые вы добавите, будут иметь доступ к управлению мероприятием!</p>

                <!-- Дополнительные ссылки -->
                <label for="eventAdditionalLinks">Дополнительные ссылки</label>
                <div v-for="(link, index) in event.additionallinks" :key="index" class="link-input">
                  <input type="text" class="form-input" placeholder="Ссылка" v-model="event.additionallinks[index]">
                  <button type="button" @click="removeLink(index)" class="btn-remove">Удалить</button>
                </div>
                <button type="button" @click="addLink" class="btn-add">Добавить ссылку</button>

                <!-- Секции -->
                <label for="eventSections">Секции</label>
                <div v-for="(section, index) in event.sections" :key="index" class="section-input">
                  <input type="text" class="form-input" placeholder="Секция" v-model="event.sections[index]">
                  <button type="button" @click="removeSection(index)" class="btn-remove">Удалить</button>
                </div>
                <button type="button" @click="addSection" class="btn-add">Добавить секцию</button>

                <!-- Кнопка отправки -->
                <button type="submit" class="form-btn2">Сохранить изменения</button>
              </form>
            </div>
          </div>
        </div>
        <div class="modal-footer">
        </div>
      </div>
    </div>
  </transition>

  <!-- Индикатор загрузки -->
  <transition name="fade">
    <div v-if="loading" class="loading-overlay">
      <div class="loading-spinner"></div>
      <p>Идет загрузка данных...</p>
    </div>
  </transition>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';

export default {
  name: 'ModalEditEvent',
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isDateUnknown: false,
      isTimeUnknown: false,
      loading: false,
      months: [
        { value: 1, label: 'Январь' },
        { value: 2, label: 'Февраль' },
        { value: 3, label: 'Март' },
        { value: 4, label: 'Апрель' },
        { value: 5, label: 'Май' },
        { value: 6, label: 'Июнь' },
        { value: 7, label: 'Июль' },
        { value: 8, label: 'Август' },
        { value: 9, label: 'Сентябрь' },
        { value: 10, label: 'Октябрь' },
        { value: 11, label: 'Ноябрь' },
        { value: 12, label: 'Декабрь' },
      ],
      errors: {}, // Для хранения ошибок валидации
    };
  },
  computed: {
    ...mapState('references', [
      'organizators',
      'EventClasses',
      'EventTypes',
      'Levels',
      'EventSubTypes',
      'Members',
      'OrganizationForms',
    ]),
  },
  methods: {
    toggleDateInputs() {
      // Очищаем поля при переключении режима
      if (this.isDateUnknown) {
        this.event.datestart = '';
        this.event.dateend = '';
        this.event.timestart = '';
        this.event.timeend = '';
      } else {
        this.event.monthstart = '';
        this.event.yearstart = '';
      }
    },
    toggleTimeInputs() {
      // Очищаем поля времени при переключении режима
      if (this.isTimeUnknown) {
        this.event.timestart = '';
        this.event.timeend = '';
      }
    },
    closeModal() {
      this.$emit('close');
    },
    ...mapActions('references', [
      'getorganizators',
      'geteventclasses',
      'geteventtypes',
      'getlevels',
      'geteventsubtypes',
      'getmembers',
      'getorganizationforms',
    ]),
    ...mapActions('event', ['updateevent', 'getallevents']),

    async saveEvent() {
      this.errors = {}; // Очищаем ошибки перед валидацией
      if (this.isDateUnknown) {
        if (!this.event.monthstart) this.errors.monthstart = 'Укажите месяц';
        if (!this.event.yearstart) this.errors.yearstart = 'Укажите год';
      } else {
        if (!this.event.datestart) this.errors.datestart = 'Укажите дату начала';
        if (!this.event.dateend) this.errors.dateend = 'Укажите дату окончания';
        if (!this.isTimeUnknown) {
          if (!this.event.timestart) this.errors.timestart = 'Укажите время начала';
          if (!this.event.timeend) this.errors.timeend = 'Укажите время окончания';
        }
      }

      if (Object.keys(this.errors).length > 0) {
        return;
      }

      const formData = {
        uid: this.event.uid,
        name: this.event.name,
        datestart: this.isDateUnknown ? null : this.event.datestart,
        dateend: this.isDateUnknown ? null : this.event.dateend,
        timestart: this.isDateUnknown || this.isTimeUnknown ? null : this.event.timestart,
        timeend: this.isDateUnknown || this.isTimeUnknown ? null : this.event.timeend,
        monthstart: this.isDateUnknown ? this.event.monthstart : null,
        yearstart: this.isDateUnknown ? this.event.yearstart : null,
        description: this.event.description,
        imagename: this.event.imagename,
        organizator: this.event.organizator || '00',
        suborganizator: this.event.suborganizator.filter((suborg) => suborg),
        location: this.event.location || 'Не определено',
        eventclass: this.event.eventclass || '00',
        eventtype: this.event.eventtype || '00',
        level: this.event.level || '00',
        eventsubtype: this.event.eventsubtype || '00',
        additionallinks: this.event.additionallinks.filter((link) => link),
        members: this.event.members.filter((member) => member),
        organizationform: this.event.organizationform || '00',
        responsible: this.event.responsible || 'Не определено',
        responsiblelogin: this.event.responsiblelogin.filter((email) => email),
        sections: this.event.sections.filter((section) => section),
        isactive: this.event.isactive,
      };

      try {
        await this.updateevent(formData);
        window.alert('Мероприятие обновлено');
        this.getallevents();
        this.closeModal();
      } catch (error) {
        console.error('Ошибка при обновлении мероприятия:', error);
      }
    },

    addLink() {
      this.event.additionallinks.push('');
    },
    removeLink(index) {
      this.event.additionallinks.splice(index, 1);
    },
    addSuborganizator() {
      this.event.suborganizator.push('');
    },
    removeSuborganizator(index) {
      this.event.suborganizator.splice(index, 1);
    },
    addMember() {
      this.event.members.push('');
    },
    removeMember(index) {
      this.event.members.splice(index, 1);
    },
    addOrganizerEmail() {
      this.event.responsiblelogin.push('');
    },
    removeOrganizerEmail(index) {
      this.event.responsiblelogin.splice(index, 1);
    }
  },
  async created() {
    this.loading = true;
    await Promise.all([
      this.getorganizators(),
      this.geteventclasses(),
      this.geteventtypes(),
      this.getlevels(),
      this.geteventsubtypes(),
      this.getmembers(),
      this.getorganizationforms()
    ]);
    this.loading = false;
  }
}
</script>
<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.home {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.event-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  width: 100%;
  box-sizing: border-box;
}

.form-btn2 {
  padding: 10px;
  background-color: #ef7d00;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}

.form-btn2:hover {
  background-color: #3c218c;
}

.form-btn {
  padding: 10px;
  background-color: #3c218c;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}

.form-btn:hover {
  background-color: #ef7d00;
}

.file-input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  background-color: #f8f8f8;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}

.file-input::-webkit-file-upload-button {
  padding: 10px;
  background-color: #3c218c;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.file-input::-webkit-file-upload-button:hover {
  background-color: #3c218c;
}

/* Стили для модального окна */
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow: hidden;
  /* Убираем скролл внутри модального окна */
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 900px;
  max-height: 90%;
  margin: auto;
  border-radius: 10px;
  z-index: 1001;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
  background: #3c218c;
  color: #FFFFFF;
}

.modal-header {
  border-bottom: 1px solid #eeeeee;
  color: #FFFFFF;
  justify-content: space-between;
  align-items: center;
  /* Выравниваем элементы по центру по вертикали */
  text-align: center;
  /* Центрируем текст заголовка */
}

.modal-header h3 {
  margin: 0;
  /* Убираем отступы у заголовка */
  flex-grow: 1;
  /* Заголовок занимает все доступное пространство */
  text-align: center;
  /* Центрируем текст заголовка */
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
  overflow-y: auto;
  /* Добавляем вертикальный скролл, если контент не помещается */
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: white;
  /* Белый цвет крестика */
  margin-left: auto;
  /* Крестик справа */
}

.btn-green {
  color: white;
  background: #3c218c;
  border: 1px solid #eeeeee;
  border-radius: 20px;
  cursor: pointer;
}

.btn-add,
.btn-remove {
  padding: 10px;
  background-color: #3c218c;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 5px;
}

.btn-add:hover,
.btn-remove:hover {
  background-color: #ef7d00;
}

/* .btn-add::after {
  content: " ссылки";
} */
</style>