<template>
    <nav class="main-nav">
        <div class="left-part">
            <img src="../assets/NavBarLogo.png" alt="Logo" class="logo circle" @click="goToEventsView">
        </div>
        <div class="center-part">
            <h2>{{ currentRouteName }}</h2>
        </div>
        <div class="right-part">
            <button class="dropdown-button" @click="goToEventsView">
                <i class="fas fa-calendar-alt"></i>
                <span class="button-label"></span>
            </button>
            <button class="dropdown-button" @click="goToAuthPage">
                <i class="fas" :class="authButtonIcon"></i>
                <span class="button-label">{{ authButtonLabel }}</span>
            </button>
        </div>
    </nav>
</template>

<script>
export default {
    data() {
        return {
            routeNames: {
                '/login': 'Авторизация',
                '/register': 'Регистрация',
            },
        };
    },
    computed: {
        currentRouteName() {
            return this.routeNames[this.$route.path] || '';
        },
        authButtonIcon() {
            return this.$route.path === '/login' ? 'fa-user-plus' : 'fa-sign-in-alt';
        },
    },
    methods: {
        goToEventsView() {
            this.$router.push('/EventsView');
        },
        goToAuthPage() {
            if (this.$route.path === '/login') {
                this.$router.push('/register');
            } else {
                this.$router.push('/login');
            }
        },
    },
};
</script>

<style scoped>
.main-nav {
  background-color: #3c218c;
  overflow: hidden;
  top: 0;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
}

.circle {
  border-radius: 50%;
  width: 100%;
  height: auto;
}

.left-part {
  display: flex;
  align-items: center;
  max-width: 10%;
  max-height: auto;
  padding-left: 10px;
  background-color: rgba(255, 255, 255, 0);
  transition: background-color 0.5s ease;
  margin-left: 30px;
  border-radius: 20px;
  box-sizing: border-box;
}

.logo {
  max-width: 50%;
  max-height: auto;
  background-color: aliceblue;
  cursor: pointer;
  box-sizing: border-box;
}

.center-part {
  display: flex;
  align-items: center;
  position: relative;
  width: 30%;
  justify-content: center;
  box-sizing: border-box;
}

.right-part {
  display: flex;
  /* padding-right: 30px;
  margin-right: 30px; */
  box-sizing: border-box;
}

.burger-button {
  display: none;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  border: none;
  background: none;
  font-size: 20px;
}

.dropdown-menu {
  display: flex;
  align-items: center;
}

.dropdown-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  color: white;
  cursor: pointer;
  border-radius: 50%;
  border-style: hidden;
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
  box-shadow: 0 0 0 1px white;
  width: 30px;
  height: 30px;
  background-color: #3c218c;
  box-sizing: border-box;
}

.dropdown-button:hover {
  box-shadow: 0 0 0 1px #ef7d00;
  color: #ef7d00;
  background-color: #3c218c;
}

.active-button {
  color: #ef7d00;
  box-shadow: 0 0 0 1px #ef7d00;
}

h2 {
  color: aliceblue;
}

@media screen and (max-width: 768px) {
  .main-nav {
    padding: 0;
    justify-content: space-between;
    width: 100%;
  }
  .right-part {
  display: flex;
  box-sizing: border-box;
}
  .left-part {
    display: none;
  }

  .center-part {
    width: auto;
    margin-left: 20px;
  }

  .burger-button {
    display: flex;
    margin-right: 20px;
  }

  .dropdown-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #3c218c;
    width: 100%;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-menu.show-menu {
    display: flex;
  }

  .dropdown-button {
    margin: 10px 0;
    width: 100%;
    border-radius: 0;
    box-shadow: none;
    justify-content: flex-start;
    padding-left: 20px;
  }

  .button-label {
    margin-left: 10px;
    color: white;
  }
}

@media screen and (min-width: 769px) {
  .button-label {
    display: none;
  }
}
</style>