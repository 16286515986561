<template>
  <div>
    <navbar />
    <div v-if="events" class="content">
      <div v-if="user">
        <div class="search-filters">
          <div class="search-container">
            <input v-model="searchQuery" placeholder="Поиск по названию ..." class="search-input" />
            <button @click="showFiltersModal = true" class="filters-button">
              <i class="fas fa-filter"></i> Фильтры
            </button>
          </div>
        </div>
        <h2>Мои мероприятия</h2>
        <div v-if="isAnyFilterApplied || searchQuery">
          <div v-if="filteredMyEvents.length > 0" class="events-grid">
            <MyEventCard v-for="event in filteredMyEvents" :key="event.id" :event="event" />
          </div>
          <div v-else>
            <p>Нет мероприятий, соответствующих выбранным фильтрам</p>
          </div>
        </div>
        <div v-else>
          <div v-if="myEvents.length > 0" class="events-grid">
            <MyEventCard v-for="event in myEvents" :key="event.id" :event="event" />
          </div>
          <div v-else>
            <p>У вас нет мероприятий в качестве ответственного</p>
          </div>
        </div>
      </div>
      <div v-else class="auth-required-message">
        <h2>Для просмотра мероприятий необходимо авторизоваться</h2>
        <p>Пожалуйста, войдите в систему, чтобы продолжить.</p>
        <button class="form-btn" @click="goToLogin">
          <i class="fas fa-sign-in-alt"></i> Перейти к авторизации
        </button>
      </div>
    </div>
    <div v-else>
      <p>Загрузка данных мероприятий...</p>
    </div>
    <!-- Модальное окно с фильтрами -->
    <div v-if="showFiltersModal" class="modal-backdrop" @click="showFiltersModal = false">
      <div class="modal" @click.stop>
        <div class="modal-header">
          <h3>Фильтры</h3>
          <button @click="showFiltersModal = false" class="close-button">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="filter-grid">
            <!-- Фильтр по дате -->
            <div class="filter-item">
              <label for="dateRange">Дата:</label>
              <input type="date" id="dateRangeStart" v-model="dateRange[0]" />
              <input type="date" id="dateRangeEnd" v-model="dateRange[1]" />
              <button @click="resetDateFilter" class="reset-filter-button">
                <i class="fas fa-times"></i> Сбросить
              </button>
            </div>

            <!-- Фильтр по организатору -->
            <div class="filter-item">
              <label for="organizator">Организатор:</label>
              <select id="organizator" v-model="selectedOrganizator" multiple>
                <option v-for="org in organizators" :key="org.uid" :value="org.uid">{{ org.fullname }}</option>
              </select>
              <button @click="resetOrganizatorFilter" class="reset-filter-button">
                <i class="fas fa-times"></i> Сбросить
              </button>
            </div>

            <!-- Фильтр по уровню -->
            <div class="filter-item">
              <label for="level">Уровень:</label>
              <select id="level" v-model="selectedLevel" multiple>
                <option v-for="lvl in Levels" :key="lvl.uid" :value="lvl.uid">{{ lvl.name }}</option>
              </select>
              <button @click="resetLevelFilter" class="reset-filter-button">
                <i class="fas fa-times"></i> Сбросить
              </button>
            </div>

            <!-- Фильтр по подтипу -->
            <div class="filter-item">
              <label for="subType">Подтип:</label>
              <select id="subType" v-model="selectedSubType" multiple>
                <option v-for="subType in EventSubTypes" :key="subType.uid" :value="subType.uid">{{ subType.name }}</option>
              </select>
              <button @click="resetSubTypeFilter" class="reset-filter-button">
                <i class="fas fa-times"></i> Сбросить
              </button>
            </div>

            <!-- Фильтр по участникам -->
            <div class="filter-item">
              <label for="member">Участники:</label>
              <select id="member" v-model="selectedMember" multiple>
                <option v-for="mem in Members" :key="mem.uid" :value="mem.uid">{{ mem.name }}</option>
              </select>
              <button @click="resetMemberFilter" class="reset-filter-button">
                <i class="fas fa-times"></i> Сбросить
              </button>
            </div>

            <!-- Фильтр по форме организации -->
            <div class="filter-item">
              <label for="organizationForm">Форма организации:</label>
              <select id="organizationForm" v-model="selectedOrganizationForm" multiple>
                <option v-for="form in OrganizationForms" :key="form.uid" :value="form.uid">{{ form.name }}</option>
              </select>
              <button @click="resetOrganizationFormFilter" class="reset-filter-button">
                <i class="fas fa-times"></i> Сбросить
              </button>
            </div>
          </div>

          <!-- Кнопка "Сбросить все" -->
          <button @click="resetFilters" class="reset-button">
            <i class="fas fa-trash-alt"></i> Сбросить все
          </button>
        </div>
        <div class="modal-footer"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import navbar from "@/components/navbar.vue";
import customfooter from "@/components/footer.vue";
import MyEventCard from '@/components/EventCardForAdmin.vue';
import moment from 'moment';

export default {
  name: 'MyEventsView',
  components: {
    navbar,
    MyEventCard,
    customfooter,
  },
  data() {
    return {
      searchQuery: '',
      showFiltersModal: false,
      dateRange: [],
      selectedOrganizator: [],
      selectedLevel: [],
      selectedSubType: [],
      selectedMember: [],
      selectedOrganizationForm: [],
    };
  },
  computed: {
    ...mapState({
      events: state => state.event.events,
      organizators: state => state.references.organizators,
      Levels: state => state.references.Levels,
      EventSubTypes: state => state.references.EventSubTypes,
      Members: state => state.references.Members,
      OrganizationForms: state => state.references.OrganizationForms,
    }),
    ...mapGetters({
      user: 'user/user'
    }),
    myEvents() {
      return this.events.filter(event => {
        const responsibleMatch = event.responsiblelogin && event.responsiblelogin.includes(this.user.email);
        return responsibleMatch;
      });
    },
    filteredMyEvents() {
      return this.myEvents.filter(event => {
        const searchMatch = event.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                            event.description?.toLowerCase().includes(this.searchQuery.toLowerCase());
        const dateMatch = this.dateRange.length ? moment(event.datestart).isBetween(this.dateRange[0], this.dateRange[1], 'day', '[]') : true;
        const organizatorMatch = this.selectedOrganizator.length ? this.selectedOrganizator.includes(event.organizator) : true;
        const levelMatch = this.selectedLevel.length ? this.selectedLevel.includes(event.level) : true;
        const subTypeMatch = this.selectedSubType.length ? this.selectedSubType.includes(event.eventsubtype) : true;
        const memberMatch = this.selectedMember.length ? this.selectedMember.some(member => event.members.includes(member)) : true;
        const organizationFormMatch = this.selectedOrganizationForm.length ? this.selectedOrganizationForm.includes(event.organizationform) : true;
        return searchMatch && dateMatch && organizatorMatch && levelMatch && subTypeMatch && memberMatch && organizationFormMatch;
      });
    },
    isAnyFilterApplied() {
      return this.dateRange.length > 0 ||
             this.selectedOrganizator.length > 0 ||
             this.selectedLevel.length > 0 ||
             this.selectedSubType.length > 0 ||
             this.selectedMember.length > 0 ||
             this.selectedOrganizationForm.length > 0;
    }
  },
  methods: {
    ...mapActions({
      getUserByUid: 'user/getUserByUid',
      getAllEvents: 'event/getallevents',
      getorganizators: 'references/getorganizators',
      getlevels: 'references/getlevels',
      geteventsubtypes: 'references/geteventsubtypes',
      getmembers: 'references/getmembers',
      getorganizationforms: 'references/getorganizationforms'
    }),
    goToLogin() {
      this.$router.push('/');
    },
    resetDateFilter() {
      this.dateRange = [];
    },
    resetOrganizatorFilter() {
      this.selectedOrganizator = [];
    },
    resetLevelFilter() {
      this.selectedLevel = [];
    },
    resetSubTypeFilter() {
      this.selectedSubType = [];
    },
    resetMemberFilter() {
      this.selectedMember = [];
    },
    resetOrganizationFormFilter() {
      this.selectedOrganizationForm = [];
    },
    resetFilters() {
      this.dateRange = [];
      this.selectedOrganizator = [];
      this.selectedLevel = [];
      this.selectedSubType = [];
      this.selectedMember = [];
      this.selectedOrganizationForm = [];
    }
  },
  async beforeMount() {
    await this.getUserByUid();
    await this.getAllEvents();
    await Promise.all([
      this.getorganizators(),
      this.getlevels(),
      this.geteventsubtypes(),
      this.getmembers(),
      this.getorganizationforms()
    ]);
  }
}
</script>

<style scoped>
.no-scroll {
  overflow: hidden;
}

.search-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.download-buttons {
  display: flex;
  gap: 10px;
}

.download-button {
  padding: 10px 20px;
  margin: 5px 5px;
  background-color: #3c218c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: #ef7d00;
}

.events-grid {
  display: grid;
  grid-template-columns: repeat(5, minmax(250px, 1fr));
  grid-gap: 24px;
  padding-inline: 70px;
}

@media screen and (max-width: 1450px) {
  .events-grid {
    grid-template-columns: repeat(3, minmax(250px, 1fr));
  }
}

@media screen and (max-width: 900px) {
  .events-grid {
    grid-template-columns: repeat(2, minmax(250px, 1fr));
  }
}

@media screen and (max-width: 600px) {
  .events-grid {
    grid-template-columns: repeat(1, minmax(250px, 1fr));
    padding-inline: 20px;
  }
}

.content {
  margin-top: 70px;
  /* Отступ сверху для основного контента */
}

.events-grid h2 {
  grid-column: 1 / -1;
  /* Занимает всю ширину */
}

h2,
h3 {
  text-align: left;
  padding: 10px;
  padding-left: 70px;
}

/* Стили для поля поиска */
.search-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.search-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  /* Закругленные углы */
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  /* Плавные переходы */
}

.search-input:focus {
  border-color: #66afe9;
  box-shadow: 0 0 5px rgba(102, 175, 233, 0.6);
}

.search-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
  font-size: 18px;
  pointer-events: none;
  /* Иконка не будет реагировать на клики */
}

.filters-button {
  display: flex;
  flex-direction: row;
  background-color: #3c218c;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
  transition: background-color 0.3s ease;
}

.filters-button:hover {
  background-color: #ef7d00;
}

/* Стили для модального окна */
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow: hidden;
  /* Убираем скролл внутри модального окна */
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 900px;
  max-height: 90%;
  margin: auto;
  border-radius: 10px;
  z-index: 1001;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
  background: #3c218c;
  color: #FFFFFF;
}

.modal-header {
  border-bottom: 1px solid #eeeeee;
  color: #FFFFFF;
  justify-content: space-between;
  align-items: center;
  /* Выравниваем элементы по центру по вертикали */
  text-align: center;
  /* Центрируем текст заголовка */
}

.modal-header h3 {
  margin: 0;
  /* Убираем отступы у заголовка */
  flex-grow: 1;
  /* Заголовок занимает все доступное пространство */
  text-align: center;
  /* Центрируем текст заголовка */
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
  overflow-y: auto;
  /* Добавляем вертикальный скролл, если контент не помещается */
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: white;
  /* Белый цвет крестика */
  margin-left: auto;
  /* Крестик справа */
}

.close-button:hover {
  color: #f0f0f0;
  /* Легкое затемнение при наведении */
}

.filter-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.filter-item {
  display: flex;
  flex-direction: column;
}

.filter-item label {
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* Добавляем многоточие для длинного текста */
  max-width: 100%;
  /* Ограничиваем ширину текста */
}

.filter-item select,
.filter-item input[type="date"] {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  width: 100%;
  /* Занимаем всю доступную ширину */
  box-sizing: border-box;
  /* Учитываем padding и border в ширине */
}

.filter-item select {
  height: 100px;
  overflow-y: auto;
  /* Добавляем вертикальный скролл для длинных списков */
}

.filter-item select option {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* Добавляем многоточие для длинного текста */
}

.modal-body button {
  margin-top: 20px;
  padding: 10px;
  background-color: #3c218c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal-body button:hover {
  background-color: #ef7d00;
}

.reset-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3c218c;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.reset-button:hover {
  background-color: #ef7d00;
}

.reset-button i {
  margin-right: 5px;
}

/* Медиа-запросы для мобильных устройств */
@media screen and (max-width: 768px) {
  .modal {
    width: 90%;
    max-width: 90%;
    padding: 10px;
  }

  .filter-grid {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .filter-item select {
    height: 80px;
  }

  .modal-body button,
  .reset-button {
    padding: 8px;
    font-size: 14px;
  }
}
</style>