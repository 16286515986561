<template>
  <navbar2/>
  <div class="content">
    <div class="home">
      <h2>Вход</h2>
      <form @submit.prevent="go_login">
        <p>
          Если Вы являетесь сотрудником/преподавателем/студентом ПГГПУ используйтие корпоративные данные для входа.
          <br>
          Если для регистрации Вы использовали обычную регистрацию, введите логин и
          пароль для aFishka
        </p>
        <input type="email" class="form-input" placeholder="Введите email" v-model="email">
        <div class="password-input">
          <input :type="passwordFieldType" class="form-input" placeholder="Введите пароль" v-model="password">
          <button type="button" class="eye-button" @click="togglePasswordVisibility">
            <span v-if="passwordFieldType === 'password'">
              <i class="fas fa-eye"></i>
            </span>
            <span v-else>
              <i class="fas fa-eye-slash"></i>
            </span>
          </button>
        </div>
        <button type="submit" class="form-btn">Войти</button>
        <span>Нет аккаунта? <router-link :to="{ path: '/register' }">Зарегистрироваться</router-link></span>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import navbar2 from '@/components/navbar2.vue';
export default {
  name: 'LoginPage',
  data() {
    return {
      email: '',
      password: '',
      passwordFieldType: 'password',
    };
  },
  components: {
        // MsLoginButton,
        navbar2
    },
  methods: {
    ...mapActions('auth', ['login']),
    async go_login() {
      try {
        await this.login({
          email: this.email,
          password: this.password,
        });
      } catch (error) {
        console.error('Ошибка входа:', error);
      }
    },
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    created() {
      console.log(VUE_APP_SERVER);
    },
    mounted() {
    window.scrollTo(0, 0);
  },
  },
};
</script>

<style scoped>
.password-input {
  position: relative;
  display: flex;
  align-items: center;
}

.eye-button {
  background: none;
  border: none;
  cursor: pointer;
  color: black;
  font-size: 1em;
  margin-left: -30px;
  z-index: 10;
}

.content p,
.content h2 {
  max-width: 500px;
  margin: 0 auto;
  padding: 15px;
  text-align: justify;
}

.home {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fff;
}

form {
  height: auto;
  width: 30%;
  display: flex;
  padding: 10px;
  flex-direction: column;
  row-gap: 20px;
  border: 1px solid #ef7d00;
  border-radius: 20px;
  background-color: #fff;
}

@media screen and (max-width: 768px) {
  form {
    width: 80%;
    height: auto;
    padding: 10px;
  }
}

.content {
  margin-top: 70px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

h2 {
  color: #000000;
  font-size: 2em;
}

.form-input {
  padding: 15px;
  border: none;
  border-radius: 20px;
  width: 100%;
  box-sizing: border-box;
  background-color: #f5f5f5;
}

.form-btn {
  background-color: #ef7d00;
  color: white;
  padding: 15px 30px;
  border: none;
  cursor: pointer;
  margin: 10px auto;
  border-radius: 20px;
  transition: background-color 0.3s ease;
  width: 100%;
}

.form-btn:hover {
  background-color: #3c218c;
}

a {
  color: #3c218c;
  text-decoration: none;
}

a:hover {
  color: #ef7d00;
}
</style>