<template>
      <navbar2/>
    <div class="content">
        <div class="required-note">
            <span class="required">*</span> — обязательные поля для заполнения
        </div>
        <h2>Регистрация</h2>
        <div class="home">
            <form @submit.prevent="go_register()">
                <!-- <MsLoginButton @click="handleMsLogin" /> -->
                <p>
            Если Вы являетесь сотрудником/преподавателем/студентом ПГГПУ, то вам не нужно регистрироваться. Используйтие
            корпоративные данные для входа на странице входа.
        </p>
                <!-- Email -->
                <div class="form-group">
                    <label for="email">Email <span class="required">*</span></label>
                    <input id="email" type="email" class="form-input" placeholder="Введите email" required minlength="5"
                        maxlength="40" v-model="email" />
                </div>
                <!-- Пароль -->
                <div class="form-group">
                    <label for="password">Пароль <span class="required">*</span></label>
                    <div class="password-input">
                        <input id="password" :type="passwordFieldType" class="form-input" placeholder="Введите пароль"
                            required minlength="8" maxlength="40" v-model="password" />
                        <button type="button" class="eye-button" @click="togglePasswordVisibility">
                            <span v-if="passwordFieldType === 'password'">
                                <i class="fas fa-eye"></i>
                            </span>
                            <span v-else>
                                <i class="fas fa-eye-slash"></i>
                            </span>
                        </button>
                    </div>
                    <div class="password-hint">Пароль должен быть не короче 8 символов</div>
                    <!-- Шкала надежности пароля -->
                    <div class="password-strength">
                        <div :class="['strength-bar', `strength-${passwordStrength}`]"></div>
                        <div class="strength-status">{{ passwordStatus }}</div>
                    </div>
                </div>
                <!-- Подтверждение пароля -->
                <div class="form-group">
                    <label for="confirmPassword">Подтверждение пароля <span class="required">*</span></label>
                    <div class="password-input">
                        <input id="confirmPassword" :type="confirmPasswordFieldType" class="form-input"
                            placeholder="Подтвердите пароль" required minlength="8" maxlength="40"
                            v-model="confirmPassword" />
                        <button type="button" class="eye-button" @click="toggleConfirmPasswordVisibility">
                            <span v-if="confirmPasswordFieldType === 'password'">
                                <i class="fas fa-eye"></i>
                            </span>
                            <span v-else>
                                <i class="fas fa-eye-slash"></i>
                            </span>
                        </button>
                    </div>
                </div>
                <!-- Фамилия -->
                <div class="form-group">
                    <label for="surname">Фамилия <span class="required">*</span></label>
                    <input id="surname" type="text" class="form-input" placeholder="Введите фамилию" required
                        v-model="surname" />
                </div>
                <!-- Имя -->
                <div class="form-group">
                    <label for="name">Имя <span class="required">*</span></label>
                    <input id="name" type="text" class="form-input" placeholder="Введите имя" required v-model="name" />
                </div>
                <!-- Отчество -->
                <div class="form-group">
                    <label for="thirdname">Отчество <span class="required">*</span></label>
                    <input id="thirdname" type="text" class="form-input" placeholder="Введите отчество" required
                        v-model="thirdname" />
                </div>
                <!-- Роль -->
                <div class="form-group">
                    <label for="rolein">Роль в ПГГПУ <span class="required">*</span></label>
                    <select id="rolein" class="form-input" required v-model="rolein">
                        <option value="" disabled selected>Выберите роль</option>
                        <option value="Студент ПГГПУ">Студент ПГГПУ</option>
                        <option value="Преподаватель ПГГПУ">Преподаватель ПГГПУ</option>
                        <option value="Сотрудник ПГГПУ">Сотрудник ПГГПУ</option>
                        <option value="Студент">Студент</option>
                        <option value="Преподаватель">Преподаватель</option>
                        <option value="Ученик">Ученик</option>
                        <option value="Учитель">Учитель</option>
                        <option value="Гость">Гость</option>
                    </select>
                </div>
                <!-- Организация -->
                <div class="form-group">
                    <label for="organization">Организация / учебное заведение <span class="required">*</span></label>
                    <input id="organization" type="text" class="form-input" placeholder="Введите организацию"
                        v-model="organization" @input="updateFields" />
                </div>
                <!-- Факультет -->
                <div class="form-group" v-if="organization === 'ПГГПУ' || organization === 'пггпу'">
                    <label for="faculty">Факультет</label>
                    <select id="faculty" v-model="faculty" class="form-input">
                        <option value="" disabled selected>Выберите факультет</option>
                        <option value="МФ">МФ</option>
                        <option value="ФФ">ФФ</option>
                        <option value="ФФК">ФФК</option>
                        <option value="ПиМНО">ПиМНО</option>
                        <option value="ППД">ППД</option>
                        <option value="ЕНФ">ЕНФ</option>
                        <option value="ИнЯз">ИнЯз</option>
                        <option value="ФизФ">ФизФ</option>
                        <option value="ИстФ">ИстФ</option>
                        <option value="МузФ">МузФ</option>
                        <option value="ПсФ">ПсФ</option>
                        <option value="ИНЭК">ИНЭК</option>
                        <option value="ПиСПО">ПиСПО</option>
                    </select>
                </div>
                <div class="form-group" v-else>
                    <label for="faculty">Факультет</label>
                    <input id="faculty" type="text" class="form-input" placeholder="Введите факультет"
                        v-model="faculty" />
                </div>
                <!-- Группа -->
                <div class="form-group">
                    <label for="user_group">Группа или класс</label>
                    <input id="user_group" type="text" class="form-input" placeholder="Введите группу или класс"
                        v-model="user_group" />
                </div>
                <!-- Страна -->
                <div class="form-group">
                    <label for="country">Страна <span class="required">*</span></label>
                    <select id="country" class="form-input" required v-model="country">
                        <option value="" disabled selected>Укажите страну</option>
                        <option v-for="country in countries" :key="country.name" :value="country.name">
                            {{ country.name }}
                        </option>
                    </select>
                </div>
                <!-- Кнопка регистрации -->
                <button type="submit" class="form-btn">Регистрация</button>
                <!-- Ссылка на вход -->
                <span>Уже есть аккаунт? <router-link :to="{ path: '/' }">Войти</router-link></span>
            </form>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import zxcvbn from 'zxcvbn';
import navbar2 from '@/components/navbar2.vue';
// import MsLoginButton from '@/components/MsLoginButton.vue';

export default {
    name: 'Register',
    data() {
        return {
            email: '',
            password: '',
            confirmPassword: '',
            surname: '',
            name: '',
            thirdname: '',
            rolein: '',
            organization: '',
            faculty: '',
            user_group: '',
            country: '', // По умолчанию выбрана Россия
            passwordFieldType: 'password',
            confirmPasswordFieldType: 'password',
        };
    },
    components: {
        // MsLoginButton,
        navbar2
    },
    computed: {
        ...mapState('references', ['countries']), // Список стран из хранилища
        passwordStrength() {
            const result = zxcvbn(this.password);
            return result.score; // Возвращает оценку от 0 до 4
        },
        passwordStatus() {
            switch (this.passwordStrength) {
                case 0:
                    return 'Очень слабый';
                case 1:
                    return 'Слабый';
                case 2:
                    return 'Средний';
                case 3:
                    return 'Надежный';
                case 4:
                    return 'Очень надежный';
                default:
                    return '';
            }
        },
    },
    methods: {
        ...mapActions({
            register: 'auth/register',
            getcountries: 'references/getcountries', // Действие для получения стран
        }),
        handleMsLogin(event) {
            event.preventDefault(); // Предотвращаем отправку формы
            // Логика для обработки нажатия на кнопку MS Login
        },
        async go_register() {
            if (this.password !== this.confirmPassword) {
                alert('Пароли не совпадают');
                return;
            }
            const formData = {
                email: this.email,
                password: this.password,
                surname: this.surname,
                name: this.name,
                thirdname: this.thirdname,
                rolein: this.rolein,
                organization: this.organization || "Не указано",
                faculty: this.faculty || "Не указано",
                user_group: this.user_group || "Не указано",
                authType: "jwt",
                security: "defaultuser",
                country: this.country, // Используем значение из формы
            };
            try {
                await this.register(formData);
                alert('Вы успешно зарегистрировались!');
                this.$router.push('/');
            } catch (error) {
                if (error.response && error.response.status === 405) {
                    alert('Пользователь с таким email уже зарегистрирован.');
                } else {
                    alert('Ошибка регистрации: ' + (error.response ? error.response.data.message : error.message));
                }
            }
        },
        updateFields() {
            if (this.organization !== 'ПГГПУ' && this.organization !== 'пггпу') {
                this.faculty = '';
                this.user_group = '';
            }
        },
        togglePasswordVisibility() {
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
        },
        toggleConfirmPasswordVisibility() {
            this.confirmPasswordFieldType = this.confirmPasswordFieldType === 'password' ? 'text' : 'password';
        },
    },
    async created() {
        await this.getcountries(); // Загружаем список стран
    },
    mounted() {
        window.scrollTo(0, 0);
    },
};
</script>
<style scoped>
.required-note {
    font-size: 14px;
    color: #888;
    margin-bottom: 10px;
}

.required {
    color: red;
}

.password-hint {
    font-size: 12px;
    color: #888;
    margin-bottom: 10px;
}

.password-strength {
    margin-top: 5px;
    display: flex;
    align-items: center;
}

.strength-bar {
    height: 5px;
    border-radius: 2px;
    margin-top: 5px;
    transition: width 0.3s;
}

.strength-0 {
    background-color: red;
    width: 20%;
}

.strength-1 {
    background-color: orange;
    width: 40%;
}

.strength-2 {
    background-color: yellow;
    width: 60%;
}

.strength-3 {
    background-color: lightgreen;
    width: 80%;
}

.strength-4 {
    background-color: green;
    width: 100%;
}

.strength-status {
    margin-left: 10px;
    font-size: 14px;
    color: #888;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
    color: #333;
    text-align: left;
    /* Выравнивание по левому краю */
    padding-left: 1.em;
}

.password-input {
    position: relative;
    display: flex;
    align-items: center;
}

.eye-button {
    background: none;
    border: none;
    cursor: pointer;
    color: black;
    font-size: 1em;
    margin-left: -30px;
    z-index: 10;
}

.home {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

form {
    display: flex;
    padding: 20px;
    flex-direction: column;
    width: 400px;
    row-gap: 20px;
    border: 1px solid #ef7d00;
    border-radius: 20px;
    background-color: #fff;
}

@media screen and (max-width: 768px) {
    form {
        padding: 10px;
        width: 80%;
    }
}

.content {
    margin-top: 70px;
    text-align: center;
}

h2 {
    color: #000000;
    font-size: 2em;
}

.form-input {
    padding: 15px;
    border: none;
    border-radius: 20px;
    width: 100%;
    box-sizing: border-box;
    background-color: #f5f5f5;
}

.form-btn {
    background-color: #ef7d00;
    color: white;
    padding: 15px 30px;
    border: none;
    cursor: pointer;
    margin: 10px auto;
    border-radius: 20px;
    transition: background-color 0.3s ease;
    width: 100%;
}

.form-btn:hover {
    background-color: #3c218c;
}

a {
    color: #3c218c;
    text-decoration: none;
}

a:hover {
    color: #ef7d00;
}
</style>