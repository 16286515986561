<template>
  <navbar />
  <div class="content">
    <div v-if="user" class="user-card">
      <div class="user-info">
        <!-- Блок с информацией о пользователе -->
        <div class="info-row">
          <i class="fas fa-envelope icon"></i>
          <span class="label">Email:</span>
          <span class="value">{{ user.email }}</span>
        </div>
        <div class="info-row">
          <i class="fas fa-user icon"></i>
          <span class="label">Фамилия:</span>
          <span class="value">{{ user.surname }}</span>
        </div>
        <div class="info-row">
          <i class="fas fa-user icon"></i>
          <span class="label">Имя:</span>
          <span class="value">{{ user.name }}</span>
        </div>
        <div class="info-row">
          <i class="fas fa-user icon"></i>
          <span class="label">Отчество:</span>
          <span class="value">{{ user.thirdname }}</span>
        </div>
        <div class="info-row">
          <i class="fas fa-building icon"></i>
          <span class="label">Организация:</span>
          <span class="value">{{ user.organization }}</span>
        </div>
        <div class="info-row">
          <i class="fas fa-user-tag icon"></i>
          <span class="label">Роль в ПГГПУ:</span>
          <span class="value">{{ user.rolein }}</span>
        </div>
        <div class="info-row">
          <i class="fas fa-users icon"></i>
          <span class="label">Факультет:</span>
          <span class="value">{{ user.faculty }}</span>
        </div>
        <div class="info-row">
          <i class="fas fa-users icon"></i>
          <span class="label">Группа:</span>
          <span class="value">{{ user.user_group }}</span>
        </div>
        <div class="info-row">
          <i class="fas fa-globe icon"></i>
          <span class="label">Страна:</span>
          <span class="value">{{ user.country }}</span>
        </div>
        <div class="info-row">
          <i class="fa-solid fa-shield-halved icon"></i>
          <span class="label">Статус:</span>
          <span class="value">
            <span v-if="user.security === 'defaultuser'">Пользователь</span>
            <span v-else-if="user.security === 'admin'">Администратор</span>
            <span v-else-if="user.security === 'volonter'">Волонтер</span>
            <span v-else-if="user.security === 'media'">СМИ</span>
            <span v-else-if="user.security === 'facultyresponsible'">Ответственный от факультета</span>
            <span v-else-if="user.security === 'departamentresponsible'">Ответственный от кафедры</span>
            <span v-else-if="user.security === 'science'">Научный отдел</span>
            <span v-else>Неизвестный статус</span>
          </span>
        </div>
      </div>
      <div class="user-actions">

        <div class="history-buttons">
          <h3>История</h3>
          <button class="form-btn" @click="navigateToRegistrationHistory">
            <i class="fas fa-history"></i> История регистраций
          </button>
          <button class="form-btn" @click="navigateToVisitHistory">
            <i class="fas fa-history"></i> История посещений
          </button>
          <button class="form-btn" @click="navigateToCerts">
            <i class="fas fa-award"></i> Дипломы, Сертификаты, Благодарности
          </button>
          <button class="form-btn" @click="navigateToPerformances">
            <i class="fas fa-microphone"></i> Выступления
          </button>
        </div>
        <!-- Управление аккаунтом -->
        <div class="account-management">
          <h3>Управление</h3>
          <button class="form-btn" @click="navigateToFavorite">
            <i class="fa fa-heart"></i> Избранное
          </button>
          <button class="form-btn" @click="toggleCommentsModal">
            <i class="fas fa-comments"></i> Комментарии
          </button>
          <CommentsModal v-if="showCommentsModal" :event="event" @close="toggleCommentsModal" />
          <button class="form-btn" @click="navigateToMyEvents">
            <i class="fas fa-calendar-check"></i> Управление мероприятиями
          </button>
          <button class="form-btn" @click="toggleEditModal"><i class="fas fa-edit"></i>Редактировать данные о
            себе</button>
          <EditUserDataModal v-if="showEditModal" :user="user" @close="toggleEditModal" />
          <button class="form-btn" @click="logout">
            <i class="fas fa-sign-out-alt"></i> Выйти из аккаунта
          </button>
        </div>
        <!-- Администрирование -->
        <div class="administration-buttons" v-if="user.security !== 'defaultuser'">
          <h3>Администрирование</h3>
          <button v-if="user.security === 'admin'" class="form-btn" @click="toggleTools('admin')">
            <i class="fas fa-tools"></i> Инструменты администратора
          </button>
          <button v-if="user.security === 'volonter' || user.security === 'admin'" class="form-btn"
            @click="toggleTools('volunteer')">
            <i class="fas fa-tools"></i> Инструменты волонтера
          </button>
          <!-- <button v-if="user.security === 'media' || user.security === 'admin'" class="form-btn" @click="toggleTools('media')">
            <i class="fas fa-tools"></i> Инструменты СМИ
          </button>
          <button v-if="user.security === 'facultyresponsible' || user.security === 'admin'" class="form-btn" @click="toggleTools('facultyresponsible')">
            <i class="fas fa-tools"></i> Инструменты ответственного от факультета
          </button>
          <button v-if="user.security === 'departamentresponsible' || user.security === 'admin'" class="form-btn" @click="toggleTools('departamentresponsible')">
            <i class="fas fa-tools"></i> Инструменты ответственного от кафедры
          </button>
          <button v-if="user.security === 'science' || user.security === 'admin'" class="form-btn" @click="toggleTools('science')">
            <i class="fas fa-tools"></i> Инструменты научного отдела
          </button> -->
        </div>
        <!-- Инструменты -->
        <div class="tools-container" v-if="activeTools">
          <h3>Дополнительные инструменты</h3>
          <!-- Инструменты администратора -->
          <button v-if="activeTools === 'admin'" class="form-btn" @click="navigateToReport">
            <i class="fas fa-file-alt"></i> Отчетность
          </button>
          <button v-if="activeTools === 'admin'" class="form-btn" @click="toggleModalAddVolonter">
            <i class="fas fa-person-circle-plus"></i> Добавить волонтера
          </button>
          <ModalAddVolonter v-if="showModalAddVolonter" @close="toggleModalAddVolonter" />
          <button v-if="activeTools === 'admin'" class="form-btn" @click="toggleModalAddAdmin">
            <i class="fas fa-person-circle-plus"></i> Добавить администратора
          </button>
          <ModalAddAdmin v-if="showModalAddAdmin" @close="toggleModalAddAdmin" />

          <!-- Инструменты волонтера -->
          <!-- <button v-if="activeTools === 'volunteer' || activeTools === 'admin'" class="form-btn" @click="toggleModalAddEvent"> -->
          <button v-if="activeTools === 'admin'" class="form-btn" @click="toggleModalAddEvent">
            <i class="fas fa-calendar-plus"></i> Добавить мероприятия
          </button>
          <button v-if="activeTools === 'volunteer' || activeTools === 'admin'" class="form-btn" @click="openScanner">
            <i class="fas fa-check-to-slot"></i> Сканнер QR-кодов
          </button>
          <qr-scanner-modal v-if="isScannerOpen" @close="closeScanner"></qr-scanner-modal>
          <ModalAddEvent v-if="showModalAddEvent" @close="toggleModalAddEvent" />

          <!-- Инструменты СМИ -->
          <button v-if="activeTools === 'media'" class="form-btn" @click="toggleModalAddEvent">
            <i class="fas fa-calendar-plus"></i> Добавить мероприятия
          </button>
          <button v-if="activeTools === 'media'" class="form-btn" @click="openScanner">
            <i class="fas fa-check-to-slot"></i> Сканнер QR-кодов
          </button>

          <!-- Инструменты ответственного от факультета -->
          <button v-if="activeTools === 'facultyresponsible'" class="form-btn" @click="toggleModalAddEvent">
            <i class="fas fa-calendar-plus"></i> Добавить мероприятия
          </button>
          <button v-if="activeTools === 'facultyresponsible'" class="form-btn" @click="openScanner">
            <i class="fas fa-check-to-slot"></i> Сканнер QR-кодов
          </button>

          <!-- Инструменты ответственного от кафедры -->
          <button v-if="activeTools === 'departamentresponsible'" class="form-btn" @click="toggleModalAddEvent">
            <i class="fas fa-calendar-plus"></i> Добавить мероприятия
          </button>
          <button v-if="activeTools === 'departamentresponsible'" class="form-btn" @click="openScanner">
            <i class="fas fa-check-to-slot"></i> Сканнер QR-кодов
          </button>

          <!-- Инструменты научного отдела -->
          <button v-if="activeTools === 'science'" class="form-btn" @click="toggleModalAddEvent">
            <i class="fas fa-calendar-plus"></i> Добавить мероприятия
          </button>
          <button v-if="activeTools === 'science'" class="form-btn" @click="openScanner">
            <i class="fas fa-check-to-slot"></i> Сканнер QR-кодов
          </button>
        </div>

      </div>
    </div>
    <div v-else class="auth-required-message">
      <h2>Для просмотра этой страницы необходимо авторизоваться</h2>
      <p>Пожалуйста, войдите в систему, чтобы продолжить.</p>
      <button class="form-btn" @click="goToLogin">
        <i class="fas fa-sign-in-alt"></i> Перейти к авторизации
      </button>
    </div>
  </div>
  <!-- <customfooter /> -->
</template>

<script>
import { mapActions, mapState } from 'vuex';
import navbar from "@/components/navbar.vue";
import customfooter from "@/components/footer.vue";
import CommentsModal from "@/components/CommentsModal.vue";
import EditUserDataModal from '@/components/EditUserDataModal.vue';
import ModalAddEvent from '@/components/ModalAddEvent.vue';
import ModalAddVolonter from '@/components/ModalAddVolonter.vue';
import ModalAddAdmin from '@/components/ModalAddAdmin.vue';
import QrScannerModal from '@/components/QrScannerModal.vue';

export default {
  data() {
    return {
      showCommentsModal: false,
      showEditModal: false,
      showModalAddEvent: false,
      showModalAddVolonter: false,
      showModalAddAdmin: false,
      activeTools: null,
      isScannerOpen: false
    };
  },
  computed: {
    ...mapState('user', ['user'])
  },
  watch: {
    user: {
      handler(newUser) {
        this.updateUserInfo(newUser);
      },
      deep: true,
    }
  },
  async beforeMount() {
    await this.getUserByUid();
  },
  components: {
    navbar,
    CommentsModal,
    EditUserDataModal,
    ModalAddEvent,
    ModalAddVolonter,
    ModalAddAdmin,
    customfooter,
    QrScannerModal
  },
  methods: {
    ...mapActions({
      getUserByUid: 'user/getUserByUid',
      logoutAction: 'user/logout'
    }),
    async reloadUserData() {
      await this.getUserByUid();
    },
    openScanner() {
      this.isScannerOpen = true;
    },
    closeScanner() {
      this.isScannerOpen = false;
    },
    toggleTools(tools) {
      if (this.activeTools === tools) {
        this.activeTools = null;
      } else {
        this.activeTools = tools;
      }
    },
    toggleModalAddAdmin() {
      this.showModalAddAdmin = !this.showModalAddAdmin;
    },
    toggleModalAddVolonter() {
      this.showModalAddVolonter = !this.showModalAddVolonter;
    },
    toggleModalAddEvent() {
      this.showModalAddEvent = !this.showModalAddEvent;
    },
    toggleEditModal() {
      this.showEditModal = !this.showEditModal;
    },
    toggleCommentsModal() {
      this.showCommentsModal = !this.showCommentsModal;
    },
    navigateToFavorite() {
      this.$router.push('/favorite');
    },
    navigateToReport() {
      this.$router.push('/report');
    },
    navigateToMyEvents() {
      this.$router.push('/myevents');
    },
    navigateToRegistrationHistory() {
      this.$router.push('/registration-history');
    },
    navigateToVisitHistory() {
      this.$router.push('/visit-history');
    },
    navigateToCerts() {
    this.$router.push('/user-certs');
  },
  navigateToPerformances() {
    this.$router.push('/user-performances');
  },
    async logout() {
      try {
        await this.logoutAction();
        localStorage.clear();
        this.$store.commit('user/setUser', null);
        this.$router.push('/');
        window.alert('Вы вышли из аккаунта');
      } catch (error) {
        console.error(error);
      }
    },
    updateUserInfo(newUser) {
      // console.log('Информация о пользователе обновлена:', newUser);
    },
    goToLogin() {
      this.$router.push('/login');
    },
  },
}
</script>

<style scoped>
.auth-required-message {
  text-align: center;
  margin-top: 50px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.auth-required-message h2 {
  color: #3c218c;
  margin-bottom: 10px;
}

.auth-required-message p {
  color: #666;
  margin-bottom: 20px;
}

.auth-required-message .form-btn {
  background-color: #3c218c;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.auth-required-message .form-btn:hover {
  background-color: #ef7d00;
}

.comments-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.user-info {
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 10px;
}

.info-row {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.info-row i {
  margin-right: 10px;
}

.info-row .label {
  font-weight: bold;
  margin-right: 5px;
}

.info-row .value {
  font-weight: normal;
}

.icon {
  margin-right: 10px;
  font-size: 1.2em;
}

.label {
  font-weight: bold;
  margin-right: 10px;
}

.value {
  flex-grow: 1;
}

.form-btn {
  background-color: #3c218c;
  color: white;
  padding: 15px 30px;
  border: none;
  cursor: pointer;
  margin: 10px auto;
  border-radius: 20px;
  transition: background-color 0.3s ease;
  width: 100%;
  text-align: center;
  display: block;
}

.form-btn i {
  margin-right: 10px;
}

.tool-btn i {
  margin-right: 10px;
}

.form-btn:hover {
  background-color: #ef7d00;
}

.content {
  margin-top: 70px;
}

.user-card {
  padding-inline: 70px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 900px) {
  .user-card {
    padding-inline: 0;
  }
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.user-info p {
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.user-info p i {
  margin-right: 10px;
}



a {
  color: #ef7d00;
  text-decoration: none;
}

a:hover {
  color: #3c218c;
}

.user-actions {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
  flex-direction: row;
  padding: 40px;
  align-items: first baseline;
}

@media screen and (max-width: 900px) {
  .user-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .account-management,
  .administration-buttons,
  .tools-container,
  .history-buttons {
    width: 100%;
    margin: 10px 0;
  }

  .form-btn {
    width: 100%;
    margin: 10px 0;
  }
}

.user-actions button {
  flex: 1 0 calc(33.33% - 20px);
  margin: 10px;
  justify-content: center;
  align-items: center;
}

.account-management,
.administration-buttons,
.tools-container,
.history-buttons {
  margin: 20px;
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 20px;
}

.account-management h3,
.administration-buttons h3,
.tools-container h3,
.history-buttons h3 {
  margin-bottom: 10px;
}

.tool-btn {
  background-color: #3c218c;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.3s ease;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}

.tool-btn.full-width {
  width: 50vw;
  height: auto;
  justify-content: center;
  align-items: center;
}

.tool-btn:hover {
  background-color: #ef7d00;
}

.default-user {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.default-user-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.default-user-buttons .form-btn {
  flex: 1 0 calc(33.33% - 20px);
  margin: 10px;
}
</style>