<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click.self="closeModal">
      <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription" @click.stop>
        <div class="modal-header" id="modalTitle">
          <h3>Ваши комментарии</h3>
          <button @click="closeModal" class="close-button">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body" id="modalDescription">
          <div class="body">
            <div v-for="comment in localComments" :key="comment.uid" class="comment">
              <div class="comment-text">{{ comment.text }}</div>
              <div class="comment-date">{{ formatDate(comment.createdAt) }}</div>
              <button class="btn-red" @click="deleteComment(comment.uid)">
                <i class="fas fa-trash-alt"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="modal-footer">
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'CommentsModal',
  data() {
    return {
      localComments: []
    };
  },
  async created() {
    await this.getUserByUid();
    await this.fetchCommentsForUser(this.user.uid);
    // console.log('Comments in component:', this.comments);
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    ...mapActions('comments', ['fetchCommentsForUser', 'deleteComment']),
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString();
    },
    async deleteComment(uid) {
      // console.log('Deleting comment with uid:', uid);
      await this.$store.dispatch('comments/deleteComment', uid); // Исправленный вызов
      // console.log('Comment deleted successfully');
    },
    ...mapActions({
      getUserByUid: 'user/getUserByUid'
    }),
  },
  computed: {
    ...mapGetters('comments', ['comments']),
    ...mapState('user', ['user']),
    localComments() {
      return this.comments;
    }
  }
};
</script>
<style scoped>
.comment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.comment-text {
  flex-grow: 1;
}

.comment-date {
  font-size: 0.8em;
  color: #999;
  margin-right: 10px;
}

.btn-red {
  color: white;
  background: #3c218c;
  border: 1px solid #eeeeee;
  border-radius: 20px;
}

/* Стили для модального окна */
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow: hidden;
  /* Убираем скролл внутри модального окна */
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 900px;
  max-height: 90%;
  margin: auto;
  border-radius: 10px;
  z-index: 1001;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
  background: #3c218c;
  color: #FFFFFF;
}

.modal-header {
  border-bottom: 1px solid #eeeeee;
  color: #FFFFFF;
  justify-content: space-between;
  align-items: center;
  /* Выравниваем элементы по центру по вертикали */
  text-align: center;
  /* Центрируем текст заголовка */
}

.modal-header h3 {
  margin: 0;
  /* Убираем отступы у заголовка */
  flex-grow: 1;
  /* Заголовок занимает все доступное пространство */
  text-align: center;
  /* Центрируем текст заголовка */
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
  overflow-y: auto;
  /* Добавляем вертикальный скролл, если контент не помещается */
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: white;
  /* Белый цвет крестика */
  margin-left: auto;
  /* Крестик справа */
}

.btn-green {
  color: white;
  background: #3c218c;
  border: 1px solid #eeeeee;
  border-radius: 20px;
}
</style>