export default {
  name: 'event',
  state: {
    events: [],
    favoriteEvents: [],
    currentEvent: null
  },
  mutations: {
    SET_EVENTS(state, events) {
      state.events = events;
    },
    ADD_TO_FAVORITES(state, event) {
      state.favoriteEvents.push(event);
    },
    SET_CURRENT_EVENT(state, event) {
      state.currentEvent = event;
    }
  },
  actions: {
    async addevent({ commit }, eventData) {
      try {
        const response = await fetch(`${process.env.VUE_APP_SERVER}/api/events/add`, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json; charset=utf-8',
          },
          body: JSON.stringify(eventData),
        });

        if (response.ok) {
          const newEvent = await response.json();
          commit('ADD_EVENT', newEvent);
          console.log('Мероприятие успешно добавлено');
        } else {
          console.error('Ошибка при добавлении мероприятия:', response.statusText);
        }
      } catch (error) {
        console.error('Ошибка при добавлении мероприятия:', error);
      }
    },
    async getallevents({ commit }) {
      try {
        const response = await fetch(`${process.env.VUE_APP_SERVER}/api/events/getallevents`);
        const events = await response.json();
        commit('SET_EVENTS', events);
      } catch (error) {
        console.error('Ошибка при получении мероприятий:', error);
      }
    },
    async fetchEventDetails({ commit }, eventId) {
      try {
        const response = await fetch(`${process.env.VUE_APP_SERVER}/api/events/${eventId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch event details');
        }
        const event = await response.json();
        commit('SET_CURRENT_EVENT', event);
      } catch (error) {
        console.error('Error fetching event details:', error);
      }
    },
    async getEventByUidForReport({ commit }, uid) {
      try {
        const response = await axios.get(`/api/events/report/${uid}`);
        commit('SET_EVENT', response.data);
        return response.data;
      } catch (error) {
        console.error('Ошибка при загрузке данных о мероприятии:', error);
        throw error;
      }
    },
    async updateevent({ commit }, { 
      uid,
      name, 
      datestart, 
      dateend, 
      timestart, 
      timeend, 
      monthstart, 
      yearstart, 
      description, 
      imagename, 
      organizator, 
      suborganizator, 
      location, 
      eventclass, 
      eventtype, 
      level, 
      eventsubtype, 
      additionallinks, 
      members, 
      organizationform, 
      responsible,
      responsiblelogin,
      sections,
      isactive
    }) {
      try {
        const response = await fetch(`${process.env.VUE_APP_SERVER}/api/events/update/${uid}`, {
          method: 'PUT',
          headers: {
            'Content-type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({
            name,
            datestart,
            dateend,
            timestart,
            timeend,
            monthstart,
            yearstart,
            description,
            imagename,
            organizator,
            suborganizator,
            location,
            eventclass,
            eventtype,
            level,
            eventsubtype,
            additionallinks,
            members,
            organizationform,
            responsible,
            responsiblelogin,
            sections,
            isactive
          })
        });
        if (response.ok) {
          const updatedEvent = await response.json();
          commit('SET_CURRENT_EVENT', updatedEvent);
        } else {
          console.error('Ошибка при обновлении мероприятия:', response.statusText);
        }
      } catch (error) {
        console.error('Ошибка при обновлении мероприятия:', error);
      }
    }
  },
  namespaced: true
}